import React, {Component} from 'react';

class Redirect extends Component {

	render(){
		return(
            <div className="App">

			<h1>404 Error</h1>
			<h4>The Page You're Looking For Is Busy With Your Mother</h4>
            </div>
		)
	}
}


export default Redirect;